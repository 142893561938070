.birthday-container {
    text-align: center;
    padding: 20px;
    background-color: #fff5f8;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .countdown-container {
    text-align: center;
    font-size: 2rem;
    color: #e63946;
  }
  
  .birthday-message {
    margin-bottom: 20px;
    animation: fadeIn 1s ease-in-out;
  }
  
  .birthday-photo {
    width: 300px;
    height: auto;
    margin-top: 20px;
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  .gift-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1.5rem;
    background-color: #ff5c5c;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .gift-button:hover {
    background-color: #e04848;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }