@font-face {
  font-family: 'CuteLove';
  src: local("CuteLove"), url(./fonts/CuteLove.ttf) format("truetype");
}
.chocolatewish {
  font-size: 40px;
  color: #8b4513;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "CuteLove";
}