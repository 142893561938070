/* Generic padding
.main {
    text-align: center;
    padding: 25px 50px 25px 50px;
} */
/* Field params */
.field {
    height: 500px;
    border: 2px solid black;
    position: relative;
    overflow-y: hidden;
}
/* Dot positioning */
.dot {
    /* border: 1px solid #000; */
    border-radius: 50%;
    position: absolute;
}
.btn {
    background-color: #ffffff;
    color: rgb(233, 48, 48);
    padding: 15px 32px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border: none;
    border-radius: 12px;
    transition: background-color 0.3s ease;
}
.buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: 20px;
}