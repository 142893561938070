.gallery{
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    -webkit-column-width: 33%;
}

.gallery .pics{
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}

.gallery .pics:hover{
    filter: opacity(.8);
}
@media (max-width: 991px) {
    .gallery{
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}
@media (max-width: 480px) {
    .gallery{
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        -webkit-column-width: 100%;
    }
}
